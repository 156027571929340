import React, { Component } from "react";
import { graphql } from "gatsby";
import MovLink from "../components/movlink";
// import { FaRegClock } from "react-icons/fa";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import Layout from "../layouts";
import { Helmet } from "react-helmet";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import { rhythm } from "../utils/typography";
import {
  GridHeader,
  PageHeader,
  PageSpacing,
  PaddedMobile,
} from "../utils/styles";

const studentLink = css`
  padding: ${rhythm(1 / 2)} 0;
  a {
    text-decoration: none;
  }
  h3 {
    padding: 0;
  }
`;

class Students extends Component {
  render() {
    const data = this.props.data;

    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | Students`}
          meta={[{ name: "description", content: "students" }]}
        />
        <Flex
          mx={[-1, -1, -2]}
          flexWrap="wrap"
          css={[PageSpacing, PaddedMobile]}
        >
          <Box width={1} px={[1, 1, 2]} key={`box-recent-projects`}>
            <h1 ref={this.myDivToFocus} css={PageHeader}>
              Students
            </h1>
          </Box>

          <Box width={1} px={[1, 1, 2]} py={4}>
            <h2>2022/23</h2>
          </Box>
          {yearGroup(data.allWordpressWpStudent, 2022)}

          <Box width={1} px={[1, 1, 2]} py={4}>
            <h2>2021/22</h2>
          </Box>
          {yearGroup(data.allWordpressWpStudent, 2021)}

          <Box width={1} px={[1, 1, 2]} py={4}>
            <h2>2020/21</h2>
          </Box>
          {yearGroup(data.allWordpressWpStudent, 2020)}

          <Box width={1} px={[1, 1, 2]} pt={5} pb={4}>
            <h2>2019/20</h2>
          </Box>
          {yearGroup(data.allWordpressWpStudent, 2019)}

          <Box width={1} px={[1, 1, 2]} pt={5} pb={4}>
            <h2>2018/19</h2>
          </Box>

          {yearGroup(data.allWordpressWpStudent, 2018)}
        </Flex>
      </Layout>
    );
  }
}

const yearGroup = (studentGroup, year) => {
  return (
    <>
      {studentGroup.edges
        .filter(({ node }) => node.acf.start_year == year)
        .map(({ node }) => (
          <Box
            width={[1 / 2, 1 / 2, 1 / 4]}
            p={[1, 1, 2]}
            key={`box-${node.slug}`}
          >
            <div css={studentLink} key={node.slug}>
              <MovLink to={`/students/${node.slug}`} direction="left">
                <h3 css={GridHeader}>{node.title}</h3>
              </MovLink>
            </div>
          </Box>
        ))}
    </>
  );
};

export default Students;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressWpStudent(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          acf {
            start_year
          }
        }
      }
    }
  }
`;
